import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class AllianzContactViewModel {
  readonly i18n_namespace = 'components.allianz-dashboard.allianz-contact';

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  phone = '55 520 13000';

  mail = 'atencion@allianz.com.mx';

  portal_link = 'clientes.allianz.com.mx';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);
}
