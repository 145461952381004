







































import { Component, Vue } from 'vue-property-decorator';
import AllianzContactViewModel
  from '@/vue-app/view-models/allianz-dashboard/allianz-contact-view-model';

@Component({
  name: 'AllianzContact',
})
export default class AllianzContact extends Vue {
  allianz_contact_view_model = Vue.observable(new AllianzContactViewModel());
}
